@import url(https://fonts.googleapis.com/css?family=Cutive+Mono&display=swap);
body {
  background-color: #d4d4d4;
}

#wordsearch-wrapper-wrapper {
  display: flex;
  justify-content: center;
}
#wordsearch-wrapper {
  margin: 1em;
  padding: 1em;

  background-color: #ffffff;
  
}
#wordsearch {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  
  font-family: 'Cutive Mono', monospace;
}
#wordsearch-word {
  display: flex;
  flex-direction: column;
  align-items: center;
}

